

<template>
    <div>
        <router-view />
    </div>
</template>
<script>
import { treeList } from '@/axios/apis'
import qqq from '@/axios/apis'
import _axios from '@/axios/axios'

export default {
    name: 'app',
    data() {
        return {}
    },
    async created() {
        // this.$cookies.set("Authorization", "JSESSIONID=02F63B62680B42DAC0A058E4A1B57469; Path=/guizhouline; HttpOnly", "1d"); // 设置Cookie，有效期为1天
        // let aa = this.$cookies.get("Authorization", ); // 设置Cookie，有效期为1天
        // console.log(aa);

    },
    mounted() {},
    methods: {
        onSubmit() {
            treeList().then((res) => {
                console.log(res)
            })
            // .catch.then((err) => {
            //   console.log(err)
            // })
            // .finally.then(() => {
            //   console.log(2222222222)
            // })
        },
    },
}
</script>
<style>
body {
    background: #f3f8f7;
}
* {
    padding: 0;
    margin: 0;
    border: 0;
}
</style>