import { Message } from "element-ui";
export default {
    // 信息提醒
    message: data => {
        if (Object.prototype.toString.call(data) != '[object Object]') data = { message: data }
        let {
            type = 'success', // success warning info error
            center, //文字是否居中。 false
            offset, // 距离窗口顶部的偏移量。 20
            onClose, //关闭时的回调函数 ()=>{}
            message, //信息
            closeAll = true, //关闭所有实例。
            duration = 3000, //显示时间, 毫秒。设为 0 则不会自动关闭。 3000
            iconClass, //自定义图标的类名
            showClose, //是否显示关闭按钮。 false
            customClass, //自定义类名
            dangerouslyUseHTMLString, //是否将message属性作为HTML片段处理。 false
        } = data
        if (closeAll) Message.closeAll()
        Message({
            type,
            center,
            offset,
            onClose,
            message,
            duration,
            iconClass,
            showClose,
            customClass,
            dangerouslyUseHTMLString,
        });
    },
    // 下载图片
    downloadImg(fileUrl, fileName) {
        let tmpArr = fileUrl.split('/')
        let name = fileName ? fileName : tmpArr[tmpArr.length - 1]
        window.URL = window.URL || window.webkitURL
        let xhr = new XMLHttpRequest()
        xhr.open('get', fileUrl, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
            if (this.status == 200) {
                //得到一个blob对象
                let blob = this.response
                let fileUrl = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                    ; (document.body || document.documentElement).appendChild(a)
                a.href = fileUrl
                if ('download' in a) {
                    a.download = name
                } else {
                    a.setAttribute('download', name)
                }
                a.target = '_self'
                a.click()
                a.remove()
            }
        }
        xhr.send()
    },

    // 下载文件
    downloadFile(fileUrl, fileName) {
        if (!fileUrl || !fileName) {
            console.error('请传入文件地址和名称')
            return
        }
        var a = document.createElement("a");
        a.href = fileUrl
        a.download = fileName
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
    },
    // 复制文本
    ecopyText(txt, success = function () { }) {
        // if (document.getElementById("copy-text")) {
        //   return document.getElementById("copy-text");
        // }
        var idObject = document.getElementById('copy-text');
        if (idObject != null) idObject.parentNode.removeChild(idObject);
        var input = document.createElement("input");
        input.setAttribute("type", "text");
        input.setAttribute("value", txt);
        input.setAttribute("id", "copy-text");
        input.setAttribute("style", "position:absolute;left:9000px;");
        document.body.appendChild(input);
        let copyHtml = document.getElementById("copy-text");
        copyHtml.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        success()
        input.remove()
    },

    // 获取合并table上下行参数
    customSpancustomSpan({
        tableSetting,
        tableData
    }) {
        let mergeArr = [],
            posiArr = [],
            divite = tableSetting.divite,
            size = tableSetting.columns
        tableData.map((i, index) => {
            if (index === 0) {
                new Array(size.length).fill(0).map((teItem, teIndex) => {
                    mergeArr.push([1]);
                    posiArr.push(0)
                })
            } else {
                size.map((siItem, siIndex) => {
                    // 这几个类型不合并
                    if (siItem.type == 'radio') {
                        mergeArr[siIndex].push(1);
                        posiArr[siIndex] = index;
                        // 判断当前元素与上一个元素是否相同(divite是为了区分是否为同类数据才能合并，否则上下行相同就会合并)
                    } else if (
                        (divite && i[siItem.prop] === tableData[index - 1][siItem.prop] && i[divite] === tableData[index - 1][
                            divite
                        ]) ||
                        (!divite && i[siItem.prop] === tableData[index - 1][siItem.prop] && i[divite] === tableData[index - 1]
                        [divite])) {
                        mergeArr[siIndex][posiArr[siIndex]] += 1;
                        mergeArr[siIndex].push(0);
                    } else {
                        mergeArr[siIndex].push(1);
                        posiArr[siIndex] = index;
                    }
                })
            }
        })
        // 固定左边的的CheckBox和radio需要手动添加
        if (tableSetting.isCheckbox) mergeArr.splice(0, 0, new Array(tableData.length).fill(1))
        if (tableSetting.isRadio) mergeArr.splice(0, 0, new Array(tableData.length).fill(1))
        return mergeArr
    }

}