import Vue from 'vue'
import VueRouter from 'vue-router'
import { apis } from '@/axios/apis'

import wraps from '@/utils/wraps'

Vue.use(VueRouter)
let router = new VueRouter({
  mode: 'history',
// base: '/guizhoutravel/', // 路径配置，默认不配置
  routes: [
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/404.vue'),
    },
    {
      path: '/login',
      name: '登录',
      component: () => import('@/views/login.vue'),
    },
    {
      path: '/',
      name: '主页',
      redirect: 'companys',
    },
    {
      path: '/',
      name: '主页',
      component: () => import('@/views/index.vue'),
      children: [
        // {
        //   path: '/home',
        //   name: '主页',
        //   component: () => import('@/views/home.vue'),
        // },
        {
          path: '/companys',
          name: '商户进件',
          component: () => import('@/views/companys/companys.vue'),
        },
        {
          path: '/companysDetail',
          name: '商户进件详情',
          component: () => import('@/views/companys/companysDetail.vue'),
          meta: {
            showBack: true
          }
        },

      ]
    },
  ],
})

// 菜单
// export let getMenu = async function getMenu() {
//   return await new Promise((resolve, reject) => {
//     // resolve()

//     apis.generateGraphicCode().then((res) => {
//       console.log(1)
//       let arr = [
//         {
//           path: '*',
//           component: () => import('@/views/404.vue'),
//         },
//       ]

//       addRoute(arr)

//       resolve()
//     })
//       .catch((err) => {
//         reject()
//       })
//   })
// }
// getMenu().then(() => {
//   console.log('菜单加载完毕')
// })

// function addRoute(arr) {
//   arr.map((i) => {
//     // router.addRoute(menuUrl[i.path])
//     router.addRoute(i)
//   })
// }

router.beforeEach(async (to, from, next) => {
  
  next()
  // console.log(to);
  // if (to.fullPath === "/login") {
  //   next()
  //   return
  // }

  // if (localStorage.getItem('token')) {
  //   next()
  // } else {
  //   setTimeout(() => {
  //     next('/login')
  //   }, 300)
  //   wraps.message({ type: 'warning', message: '请先登录！' })

  // }

  // if (!userState || !userState.isLogin) {
  // 	// 没有登录
  // 	// 如果前往页面非公共路由，则跳转至首页
  // 	if (staticRouterMap.indexOf(to.name) < 0) {
  // 		next({name: '/home'})
  // 	} else {
  // 		next()
  // 	}
  // } else {
  // }
})

export default router
