import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
import router from "./router/index";
import store from '@/store';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.use(ElementUI);


import gui from 'gui-element'
Vue.use(gui)

// 全局的方法
import wraps from '@/utils/wraps';
for (const key in wraps) {
  Vue.prototype[key] = wraps[key]
}
// 全局的指令
import directives from '@/utils/directives';
for (const key in directives) {
  Vue.directive(key, directives[key])
}

// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV == 'development') {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')
