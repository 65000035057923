// 储单选、多选、下拉选项列表数据
import dicts from '@/utils/dicts'
import _axios from '@/axios/axios'

const optionsModule = {
  state: {
    dicts,
    dictTaked: []
  },
  getters: {
    // 获取字典数据。 依次是 1、key=字典 2、字典里面的数据 3、接口的字典
    getOptions: (state) => {
      return (key = '') => {
        let { dicts, dictTaked } = state
        if (Object.prototype.toString.call(key) == '[object Array]') return key
        if (Object.prototype.toString.call(dicts[key]) == '[object Array]') return dicts[key]
        if (Object.prototype.toString.call(dicts[key]) == '[object Object]' && dictTaked.includes(key) === false) {
          optionsModule.mutations.SET_DICTTAKED(state, key)
          optionsModule.actions.getDict(state, dicts[key], key)
        }
        return []
      }
    },
    // getOptions: (state) => {
    //   return (keyArr) => {
    //     let obj = {}, { dicts } = state
    //     for (let index = 0; index < keyArr.length; index++) {
    //       const element = keyArr[index];
    //       obj[element] = Object.prototype.toString.call(dicts[element]) == '[object Array]' ? dicts[element] : []
    //     }
    //     return obj
    //   }
    // }
  },

  // getters: {
  //   getDictSet(state, data) {
  //     console.log(state);
  //     console.log(data);
  //     return `the account NUm is `
  //   }
  // },
  mutations: {
    // 设置选项数据
    SET_OPTIONS: (state, data) => {
      let { name = "null", value = [] } = data;
      state.dicts[name] = value;
    },
    // 字典的数据是否获取过了，有则不再次获取
    SET_DICTTAKED: (state, data) => {
      state.dictTaked.push(data);
    }
  },
  actions: {
    // 设置选项数据
    // set_options: (context, data) => {
    //   context.commit("SET_OPTIONS", data);
    // }
    // 设置选项数据
    getDict: (state, data, key) => {
      let { fields = { label: 'id', value: 'value' } } = data
      _axios(data).then((res) => {
        if (res.code == 200) {
          state.dicts[key] = res.result.map(i => {
            return {
              label: i[fields.label],
              value: i[fields.value]
            }
          })
        }
      })

      // context.commit("SET_OPTIONS", data);
    }

  }
}

export default optionsModule;

