// 字典
export default {
    state: [
        {
            value: 0,
            label: '上架'
        },
        {
            value: 1,
            label: '下架'
        },
        {
            value: 123,
            label: '下架2'
        },
        {
            value: 3424,
            label: '下架3'
        },
    ],
    stagState: [
        {
            value: 0,
            label: '上架',
            type: 'success'
        },
        {
            value: 1,
            label: '下架'
        },
        {
            value: 123,
            label: '下架2'
        }
    ],

    radio11: [{
        label: '名在',
        value: 1
    }, {
        label: '名字22',
        value: 2
    }],

    // 单选接口获取
    dcitradiosistory: {
        data: {},
        method: 'get',
        url: `/api/common/history`,
        fields: {
            label: 'title',
            value: 'year'
        },
    },
    // 单选接口获取
    dcitradiosistory2: {
        data: {
            aa: 123
        },
        method: 'get',
        loading: false,
        url: `/api/common/history`,
        // fields跟dealData是互斥的，优先执行dealData
        fields: {
            label: 'title',
            value: 'year'
        },
        dealData(data) {
            if (data.code == 200) {
                return data.result.map(i => {
                    return {
                        label: i.title,
                        value: i.year,
                    }
                })
            }
        },
    },
    // 下拉接口
    dcitradiosistory3: {
        data: {
            wwww: 'eqwqeq'
        },
        method: 'get',
        loading: false,
        url: `/api/common/history`,
        // fields跟dealData是互斥的，优先执行dealData
        fields: {
            label: 'title',
            value: 'year'
        },
        dealData(data) {
            if (data.code == 200) {
                return data.result.map(i => {
                    return {
                        label: i.title,
                        value: i.year,
                    }
                })
            }
        },
    },
}