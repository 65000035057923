/*
  全局指令
  el:html节点  binding:指令信息  node:节点各类信息
*/

let directives = {
    // 设置宽度
    width: {
        bind(el, binding) {
            if (binding.value) el.style.width = binding.value
        },
    },
    //添加滚动事件，滚到底部触发事件。可使用 className/idName指定内部盒子进行滚动（不指定则默认添加指令的盒子上添加事件）
    loadmore: {
        bind(el, binding, node) {
            let attrs = node.data.attrs, selectNode = el
            if (attrs && attrs.className) {
                selectNode = el.querySelector('.' + node.data.attrs.className);
            } else if (attrs && attrs.idName) {
                selectNode = el.querySelector('#' + node.data.attrs.idName);
            }
            selectNode.addEventListener('scroll', function () {
                if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
                    binding.value();
                }
            })
        }
    },

}

export default directives

