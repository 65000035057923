
/* es6-promise/auto:为vuex使用不支持promise浏览器的插件*/
// import 'es6-promise/auto'
import Vue from "vue"
import Vuex from 'vuex'
Vue.use(Vuex);

import userModule from "./modules/userModule";
import optionsModule from "./modules/optionsModule";
import systemModule from "./modules/systemModule"
const store = new Vuex.Store({

  // getters: {
  //   getOptions: (state) => {
  //     return (keyArr) => {
  //       let obj = {}, { dicts } = state.optionsModule
  //       for (let index = 0; index < keyArr.length; index++) {
  //         const element = keyArr[index];
  //         obj[element] = Object.prototype.toString.call(dicts[element]) == '[object Array]' ? dicts[element] : []
  //       }
  //       return obj
  //     }
  //   }
  // },

  modules: {
    userModule, optionsModule, systemModule
  }

})
//定义用户模块直接使用
// Vue.prototype.$qqqqqqqqqqqqqqqqqqqqqqqqqq = store.state.userModule;
export default store;
