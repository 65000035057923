// 公共接口
import _axios from './axios'

let preUrl = '/guizhouline/bank_api/bank'

let urlSet = {
    doLogin: `${preUrl}/doLogin22`,
}

export default ({
    url = "",
    data = {},
    method = "post",
    headers = {},
    loading = true,
    needToken = true,
    responseType = "text",
    success,
    fail,
    final
}) => {
    return _axios({
        url: `${urlSet[url]}`,
        data,
        method,
        headers,
        loading,
        needToken,
        responseType,
        fail,
        final,
        success,
    })
};




// 登录
export let doLogin = data =>
    _axios({
        data: data,
        method: 'get',
        url: `${preUrl}/doLogin`,
    });
// 退出
export let doLoout = data =>
    _axios({
        data: data,
        method: 'get',
        url: `${preUrl}/doLogout`,
    });
// 列表
export let banklist = data =>
    _axios({
        data: data,
        method: 'get',
        url: `${preUrl}/list`,
    });
// 详情
export let bankdetail = data =>
    _axios({
        data: data,
        method: 'get',
        url: `${preUrl}/detail`,
    });
// 开户
export let setMerchantNo = data =>
    _axios({
        data: data,
        method: 'get',
        url: `${preUrl}/setMerchantNo`,
    });
// 导出
export let bankexport = data =>
    _axios({
        data,
        method: 'post',
        url: `${preUrl}/export`,
        isDownload: true,
        responseType: 'blob',
      'Content-Type': 'application/x-www-form-urlencoded'
    });

// 修改状态
export let updateStatus = data =>
    _axios({
        data,
        url: `${preUrl}/updateStatus`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });





// 下载接口
export let fundCardexport = data =>
    _axios({
        data: data,
        url: `/fundCard/export`,
        responseType: 'blob'
    });
