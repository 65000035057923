import axios from "axios";
import { Loading } from "element-ui";
import wraps from '@/utils/wraps';
import config from "@/config";
import Cookies from 'js-cookie'
import router from "@/router";

/*
 axios 请求方法
 method 请求方式
 url 请求地址
 data 请求参数
 loading 是否开启loading
 needToken 是否需要提交token
 */
axios.defaults.baseURL = config.baseURL;
axios.defaults.withCredentials = true;

let _axios = options => {
    let {
        url = "",
        data = {},
        params = {},
        method = "post",
        headers = {},
        loading = true,
        needToken = true,
        responseType = "text",
        success,
        fail,
        final,
        isDownload,
    } = options;
    if (method == "get") params = data
    if (needToken === true) {
        // headers.token = getStorage("token") ? getStorage("token") : ""
    }
    if(options['Content-Type']){
       headers['Content-Type'] = options['Content-Type']
    }
    console.log(headers);
    // 加载loding
    let _loading = ''
    if (loading) {
        var queryId = typeof loading === "string" ? loading : "#app";
        _loading = Loading.service({
            target: document.querySelector(queryId),
            lock: true
        });
    }

    return new Promise((resolve, reject) => {
        axios({
            url,
            data,
            method,
            params,
            headers,
            responseType,
        }).then(res => {
            console.log(res);
            if (isDownload) {
  
                // const fileName = res.headers["content-disposition"].split("=")[1];
                const fileName = '商户.zip'
                const blob = new Blob([res.data], {
                    type: 'application/zip'
                });
                const url = window.URL.createObjectURL(blob);
                const aLink = document.createElement("a");
                aLink.style.display = "none";
                aLink.href = url;
                aLink.setAttribute("download", decodeURI(fileName));
                document.body.appendChild(aLink);
                aLink.click();
                document.body.removeChild(aLink);
                window.URL.revokeObjectURL(url);

                if (res.status === 200) {
                    wraps.message({ type: "success", message: '操作成功!' })
                } else {
                    wraps.message({ type: "error", message: '请求出错，请稍后重试！' })
                }
            }

            let resData = {}
            if (res && res.data) resData = JSON.parse(res.data)
            if (resData.code === 0) {
                success ? success(res) : ''
                resolve(resData.msg);
            } else {
                if (resData.code === 1001) {
                    router.replace({ path: "/login" });
                }
                fail ? fail(res) : ''
                wraps.message({ type: "error", message: resData.error ? resData.error : '请求出错，请稍后重试！' })
                reject(res);
            }
        }).catch((err) => {
            fail ? fail(err) : ''
            reject(err);
            // wraps.message({ type: "error", message: err.message ? err.message : '请求出错，请稍后重试！！' })
        }).finally(() => {
            final ? final() : ''
            _loading ? _loading.close() : "";
        });
    });
};
axios.interceptors.request.use(
    config => {

        // config.headers['Cookie']='Bearer '+ 'JSESSIONID=C68D80A00ECA75D1A9262C596578FDA8'
        // console.log($cookies.get('Authorization'));

        // config.headers['Cookie'] = Cookies.get('Authorization')
        // config.headers['Authorization'] = 'JSESSIONID=F79083631873C5FD7DFF41B7B20D87CA'
        // config.headers['XSRF-TOKEN'] = 'JSESSIONID=F79083631873C5FD7DFF41B7B20D87CA'
        // config.headers['X-XSRF-TOKEN'] = 'JSESSIONID=F79083631873C5FD7DFF41B7B20D87CA'
        // config.headers['XSRF-TOKEN'] = 'F79083631873C5FD7DFF41B7B20D87CA'
        // config.headers['XSRF-TOKEN'] = 'F79083631873C5FD7DFF41B7B20D87CA'
        // config.headers['Cookie'] = 'F79083631873C5FD7DFF41B7B20D87CA'


        // JSESSIONID=C3A4CE329DA54EF86BF3E189187C07EB; Path=/guizhouline; HttpOnly


        // config.headers.Cookie=JSESSIONID=B8CC9786DD7EFA8DAD24E525F3442DF1

        return config;
    },
    // error => {
    //     return Promise.reject(error)   // 返回接口返回的错误信息
    // }
);


axios.interceptors.response.use(
    response => {
        // this.$cookies.set("Authorization", "JSESSIONID=02F63B62680B42DAC0A058E4A1B57469; Path=/guizhouline; HttpOnly", "1d"); // 设置Cookie，有效期为1天
        // console.log($cookies.get('Authorization'));
        // console.log($cookies);
        // console.log($cookies);
        // console.log(response.config.headers.Authorization);
        // console.log(response.config.headers.Authorization);
        // console.log(response.config.headers.Authorization);
        // Cookies.set('XSRF-TOKEN','JSESSIONID=D9B9BD7329CAF1B8D3CB86B38E7029BB')
        // Cookies.set('X-XSRF-TOKEN','JSESSIONID=D9B9BD7329CAF1B8D3CB86B38E7029BB')
        // Cookies.set('Cookie','JSESSIONID=D9B9BD7329CAF1B8D3CB86B38E7029BB')

        // console.log(response);
        if (response?.config?.headers?.Authorization) {
            // $cookies.set('cookies',response?.config?.headers?.Authorization)
        }
        return response;
    },
    error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
            wraps.message({ type: "error", message: "网络错误,请稍后再试！！" })
        } else if (error.code == "ERR_BAD_REQUEST") {
            wraps.message({ type: "error", message: "接口不存在！！" })
        }
        return Promise.reject(error)   // 返回接口返回的错误信息
    }
);

export default _axios;