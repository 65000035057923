// import { getStorage, setStorage, cleanStorage, cleanCookie, delCookie } from "@/utils/myUtils";

const userModule = {
  state: {
    qqq:{},
    qqq2:{w:333},
    qqq3:{},
    qqq4:{},
    // token: getStorage("token"),
    // adminToken: getStorage("adminToken"),
    // adminUserInfo:getStorage("adminUserInfo"),
    // userInfo: getStorage("userInfo") || getStorage("menuUserInfo"),
  },
  getters: {
    // getMenuList: state => {
    // }
  },
  mutations: {//同步
    // // 设置超级管理员token
    // SET_ADMINTOKEN:(state,token)=>{
    //   setStorage('adminToken',token);
    //   state.adminToken=token
    // },
    // // 清除用户数据
    // CLEAN_USER_INFO() {
    //   cleanStorage("token");
    //   cleanStorage("adminToken");
    //   cleanStorage("userInfo");
    //   cleanStorage("navindex");
    //   delCookie();
    // },
  },
  actions: {//异步
    //  // 模拟登录
    //  async simulationLogin({ commit, state }, data) {
    //   let result = await APILOGIN.setlogin({ id: data.id });
    //   // 清除缓存的套餐详情
    //   commit("SET_ADMINTOKEN");
    // }
  }
};

export default userModule;
