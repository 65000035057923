let dev = {
  // baseURL: "https://guizhouline.jiyar.net",
  baseURL: "https://guizhoutravel.jiyar.net",
}

let test = {
  baseURL: "https://guizhoutravel.jiyar.net",
}

let uat = {
  baseURL: "https://guizhoutravel.jiyar.net",
}

let pro = {
  baseURL: "",
}

const getBaseUrlByHost = function () {
  const host = window.location.host
  let base = pro
  if (host.indexOf("localhost") !== -1) {
    base = dev
  } else if (host.indexOf("test") !== -1) {
    base = test
  } else if (host.indexOf("uat") !== -1) {
    base = uat
  }

  base = {
    baseURL: "",
  }

  console.log("当前环境配置11", base)
  return {}
}


export default getBaseUrlByHost()