const systemModule = {
  state: {
  },
  getters: {
    // getMenuList: state => {
    // }
  },
  mutations: {//同步
  },
  actions: {//异步
  }
};

export default systemModule;
